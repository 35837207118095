<template>
    <company-component/>
</template>

<script>
    import CompanyComponent from "@/components/admin/CompanyComponent";
    
    export default {
        name: "Company",
        title: "Datos de la Organización | Turismo BC",
        components: {
            CompanyComponent,
        }
    }
</script>

<style scoped>

</style>